<template>
   <div>
     <b-row>
          <b-col  >
            <p align="left" >
                  <b-form-group
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                  >
                    <b-input-group size="sm">
                            <b-form-input
                              id="filter-input"
                              v-model="filter"
                              type="search"
                              placeholder="Pesquise aqui..."
                            >
                            </b-form-input>

                            <b-input-group-append>
                              <b-button :disabled="!filter" @click="filter = ''">Limpar</b-button>
                            </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
              </p>
          </b-col> 

          <b-col >
            <p align="left" class="pesquisa_select" >
                        <b-button @click="createItem"  pill 
                        variant="success" 
                        size="sm">Novo Registro</b-button>
                 <!-- <b-button @click="pesquisarFuncao" pill variant="success" size="sm"> Pesquisar </b-button> -->
                   &nbsp;
                   <b-button  type="button" scale="2" variant="outline-success"  v-on:click="download" ><b> Excel</b> <b-icon icon="file-excel" ></b-icon></b-button>
            </p>
          </b-col>
     </b-row>

     <b-table striped hover responsive
         id="my-table"
        :busy="isBusy"
        :items="tableProcedimentos" 
        :filter="filter" 
        :fields="visibleFields"
        :per-page="perPage"
        :current-page="currentPage"
        aria-controls="my-table"
        small
        >

            <template #cell(index)="data">{{ data.index + 1 }}</template>

            <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
            </template>

            <template #cell(opcao)="data">
                <b-icon icon="pencil-fill" 
                    cursor= "pointer" 
                    @click="editItem(data.item)" pill 
                    variant="primary" 
                    size="sm">
                </b-icon>
                    &nbsp;
                <b-icon icon="trash" 
                    cursor= "pointer" 
                    @click="deleteItem(data.item)" pill 
                    v-b-modal="'edit-modal'" 
                    variant="primary" 
                    size="sm">
                </b-icon>
            </template>

      </b-table>

           <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
            ></b-pagination>

          
          <!-- Info modal -->
           <b-modal v-model="modalShow"  hide-footer>
            <b-form @submit.prevent="save">
                
                      <b-form-group id="input-group-2" label="Nome:" label-for="input-2">
                        <b-form-input
                                id="input-2"
                                hidden="true"
                                v-model="formdata.id"
                                placeholder="id"
                        ></b-form-input>
                        <b-form-input
                                id="input-2"
                                v-model="formdata.name"
                                required
                                placeholder="Nome"
                        ></b-form-input>
                      </b-form-group>

                    
                <br/>
                <b-button size="sm" pill variant="danger" @click="close" >
                    Cancelar
                </b-button>
                &nbsp;
                <b-button type="submit" pill size="sm" variant="success">
                    Salvar
                </b-button>
            </b-form>
        </b-modal>
   
          
    </div>
</template>


<script>
//import {api} from '@/plugins/axios'
import {  mapState, mapActions } from 'vuex'
import maskMixins from '@/helpers/mixins/mask'
import * as XLSX from 'xlsx'

export default {

        mixins:[maskMixins], 

        data () {
          return {
              isBusy: false,
              perPage: 2,
              currentPage: 1,
              filter: null,
              infoModal: {
                        id: 'info-modal',
                        title: '',
                        content: ''
                        },
              fields:[  
                      {
                        key:'index',
                        label:'Indice',
                        visible: true,
                        formatter: 'index'
                      },
                      {
                        key: 'id',
                        label:'id',
                        visible: false,
                        sortable: true,
                        thStyle: { width: "60%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      {
                        key: 'name',
                        label:'Especialidade Médica',
                        sortable: true, 
                        visible: true,
                        thStyle: { width: "60%"},
                        tdClass: 'fonteLinhasLeft'
                      },
                      
                      {
                        key: 'opcao',
                        visible: true,
                        label:'Opções',
                        sortable: true
                      },
                                    
          ],
             formdata:{},
             modalShow: false,
             editedIndex: -1
          }
        },
       
       mounted(){
            
         this.ActionFindEspecialidade(),
             setTimeout(() =>{// aguarda com spinner antes da pesquisa aparecer na pesquisa inicial
                  this.isBusy = false
                  }, 2.0*2000)
        },
        computed:{
           visibleFields() {
                return this.fields.filter(field => field.visible)
              },
           rows() {

                 return this.tableProcedimentos.length
                  },
                  ...mapState('Procedimentos', ['tableProcedimentos'])
        },
        methods: {
          
                   ...mapActions('Procedimentos', ['ActionFindEspecialidade']),
                   ...mapActions('Procedimentos', ['ActionSalvar']),
                   ...mapActions('Procedimentos', ['ActionEditar']),
                   ...mapActions('Procedimentos', ['ActionDeletar']),

    download: function () {
      const data = XLSX.utils.json_to_sheet(this.tableProcedimentos)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb, 'table_procedimentos.xlsx')
    },


            createItem() {
                        this.modalShow = true;
                        this.formdata = {}
                        this.editedIndex = -1;
                    },

                    editItem(item) {

                        this.modalShow = true;
                        this.editedIndex = this.tableProcedimentos.indexOf(item);
                        this.formdata = Object.assign({}, item);
                    },

                    close() {
                              this.modalShow = false;
                              setTimeout(() => {
                                this.formdata = {}
                                this.editedIndex = -1;
                                this.ActionFindEspecialidade()
                              }, 300);
                     },

              async save() {

                      try {

                            if(!this.formdata.id){//entra para salvar

                                await this.ActionSalvar(this.formdata)
                                this.ActionFindEspecialidade()
                                alert('Cadastrado com sucesso!')
                            }

                            else{//entra para editar
                                  await this.ActionEditar(this.formdata)
                                  this.ActionFindEspecialidade()
                                  alert('Atualizado com sucesso!')
                            }

                            this.formdata = {} 

                      } catch (err) {
                          alert(err.data ? err.data.message : 'Não foi possível salvar produto')
                        }
                },  
              
              async deleteItem(data){

                            const index = this.tableProcedimentos.indexOf(data);  

                            if(confirm('Deseja excluir o item?') && this.tableProcedimentos.splice(index, 1) ){
                                await this.ActionDeletar(data.id)
                                this.editedItem = Object.assign({}, data); 
                            }
                      },

                // async pesquisarFuncao() {
                //                 //  this.isBusy = !this.isBusy //loading
                //                 //     await this.ActionFindByExercicio(this.formdata).then({})
                //                 //   this.isBusy = false
                //   },
                  mascaraCnpj(value) {
                            var mascara = (`${value}`).replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
                            return mascara;
                  },

                  formatarData: function (value) {
                       if (value === null) { return null }
                      return new Date(value).toLocaleString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit', hour:'2-digit', minute:'2-digit', second:'2-digit' })
                  },
                  
                  resetInfoModal() {
                                      this.infoModal.title = ''
                                      this.infoModal.content = ''
                  }

          }
  }
</script>
<style >
.fonteLinhas {
   font-size:14px;
   text-align: center
}
.fonteLinhasLeft {
   font-size:14px;

}
.select-selected {
  border-color: black;
  border: 6px solid;
}

.select-selected.select-arrow-active:after {
  border-color: black;
  top: 7px;
}
.select-items div,
.select-selected {
  color: black;
  padding: 8px 16px;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.select-hide {
  display: none;
}

.pesquisa_select{

  position: relative;
  margin-top: 20px;

}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
@mixin flex-center($columns: false) {
  display: flex;
  align-items: center;
  justify-content: center;
  @if $columns {
    flex-direction: column;
  }
}

</style>